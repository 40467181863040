<template>
  <div class="videoppt-box">
    <div class="operate-title">
      <div class="back cursor" @click="goBack">
        <i></i>
      </div>
      <div
        class="change-icon"
        :class="isFull ? 'cur' : ''"
        @click="isFull = !isFull"
      ></div>
    </div>
    <div class="play-content" :class="isFull ? 'cur' : ''">
      <div class="content-box" ref="contentbox">
        <!-- video或者ppt文件 -->
        <div class="content" v-if="curType == 'video'">
          <video
            :width="clientWidth"
            :height="clientHeight"
            controls
            ref="video"
          >
            <source :src="playUrl" />
            <!-- type="video/mp4" -->
          </video>
        </div>
        <div class="content" v-if="curType == 'ppt'">
          <iframe
            id="iframe2"
            :width="clientWidth"
            :height="clientHeight"
            frameborder="no"
            border="0"
            marginwidth="0"
            marginheight="0"
            scrolling="no"
            allowtransparency="yes"
            :src="playUrl"
          ></iframe>
        </div>
      </div>
      <div class="btn-box">
        <div class="pre btn fcenter" @click="btnHandle('pre')">
          <i></i>
        </div>
        <div class="next btn fcenter" @click="btnHandle('next')">
          <i></i>
        </div>
      </div>
    </div>
    <div class="slide-bar" :class="isFull ? 'cur' : ''">
      <ul>
        <li
          :class="item.choosed ? 'cur' : ''"
          v-for="(item, index) in comList"
          :key="item.sort"
          @click="curIndex = index"
        >
          <p>
            {{ item.title }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { courseInfoApi } from '@/api/user'
// let fromName = ''
// let query = ''
export default {
  data() {
    return {
      playUrl: '',
      clientWidth: '',
      clientHeight: '',
      isFull: false,
      curIndex: 0,
      curType: 'video', // ppt 或者 video
      comList: [],
      query: ''
    }
  },
  mounted() {
    console.log(this.$router, 'fff')
    let contentDom = this.$refs.contentbox
    let id = this.$route.query.id || 5
    this.getList(id)
    this.clientHeight = contentDom.clientHeight
    this.clientWidth = contentDom.clientWidth
    window.onresize = () => {
      return (() => {
        this.clientHeight = contentDom.clientHeight
        this.clientWidth = contentDom.clientWidth
      })()
    }
  },
  methods: {
    // 获取视频ppt列表
    getList(id) {
      courseInfoApi({
        id,
        type: 'content'
      }).then(res => {
        if (res.code === 0) {
          res.data.list.forEach((item, index) => {
            item.choosed = false
            if (index === 0) {
              item.choosed = true
              this.curType = item.type
              if (item.type === 'video') {
                let video = this.$refs.video
                video.src = item.info
              } else {
                if (item.info.indexOf('https') == -1) {
                  this.playUrl = `https://ow365.cn/?i=${exporeConstNoChange.pptId}&furl=${item.info}`
                } else {
                  this.playUrl = `https://ow365.cn/?i=${exporeConstNoChange.pptId}&ssl=1&furl=${item.info}`
                }
              }
            }
          })
          this.comList = res.data.list
        }
      })
    },
    // 底下的按钮事件
    btnHandle(val) {
      if (val === 'pre' && this.curIndex !== 0) {
        this.curIndex -= 1
      }
      if (val === 'next' && this.curIndex !== this.comList.length - 1) {
        this.curIndex += 1
      }
    },
    // 改变触发
    curIndexChange(val) {
      this.comList.forEach((item, index) => {
        item.choosed = false
        if (val === index) {
          item.choosed = true
          this.playUrl = item.info
          this.curType = item.type
          if (this.curType === 'ppt') {
            if (item.info.indexOf('https') == -1) {
              this.playUrl = `https://ow365.cn/?i=${exporeConstNoChange.pptId}&furl=${item.info}`
            } else {
              this.playUrl = `https://ow365.cn/?i=${exporeConstNoChange.pptId}&ssl=1&furl=${item.info}`
            }
          }
        }
      })
    },
    goBack() {
      let fromName = sessionStorage.getItem('fromName')
      let query = sessionStorage.getItem('query')
      this.$router.replace({ name: fromName, query: JSON.parse(query) })
      // window.history.length > 1
      //   ? this.$router.go(-1)
      //   : this.$router.replace('/')
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name) {
      sessionStorage.setItem('fromName', from.name)
      sessionStorage.setItem('query', JSON.stringify(from.query))
    }
    next()
  },
  watch: {
    isFull() {
      setTimeout(() => {
        this.clientHeight = this.$refs.contentbox.clientHeight
        this.clientWidth = this.$refs.contentbox.clientWidth - 25
      }, 200)
    },
    curIndex(val) {
      this.curIndexChange(val)
      if (this.curType === 'video') {
        this.$nextTick(() => {
          let video = this.$refs.video
          video.src = this.playUrl
        })
      }
    }
  }
}
</script>

<style></style>
